import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  writeBatch,
  Unsubscribe,
} from "firebase/firestore";

export const useLevels = definePiniaStore("/levels", () => {
  const allLevels = ref<Levels>([]);

  const isInitialized = ref(false);
  const subscription = ref<Unsubscribe | undefined>();

  const initializeLevels = async () => {
    if (isInitialized.value) return;

    const db = useFirestore();
    const levelsCollectionRef = collection(db, "levels");
    const levelsCollectionQuery = query(
      levelsCollectionRef,
      orderBy("displayOrder", "asc")
    );

    var levelsSnapshot = await getDocs(levelsCollectionRef);
    allLevels.value = levelsSnapshot.docs.map((doc) => {
      return {
        ...doc.data(),
        id: doc.id,
      } as Level;
    });

    if (subscription.value) {
      subscription.value();
    }

    subscription.value = onSnapshot(levelsCollectionQuery, (snapshot) => {
      allLevels.value = snapshot.docs.map((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        } as Level;
      });
    });

    isInitialized.value = true;
  };

  const allUserLevels = computed(() => {
    const appUserData = useAppUserData();
    const { availableLevelIds } = storeToRefs(appUserData);

    if (
      availableLevelIds.value == undefined ||
      availableLevelIds.value.length == 0
    )
      return allLevels.value;

    return allLevels.value.filter((level) => {
      return availableLevelIds.value.includes(level.id!);
    });
  });

  const productCategoryLevels = computed(() => {
    return (productCategoryId: string) => {
      return allLevels.value.filter((level) => {
        return level.iewGradebookProductCategoryId == productCategoryId;
      });
    };
  });

  const fetchLevel = async (levelId: string) => {
    const db = useFirestore();
    const levelRef = doc(db, "levels", levelId);
    const levelDoc = await getDoc(levelRef);

    if (levelDoc.exists()) {
      return {
        ...levelDoc.data(),
        id: levelDoc.id,
      } as Level;
    } else {
      return null;
    }
  };

  const saveLevel = async (level: Level) => {
    const db = useFirestore();
    if (level.id) {
      const levelRef = doc(db, "levels", level.id);
      await updateDoc(levelRef, level);
    } else {
      const levelsCollectionRef = collection(db, "levels");
      await addDoc(levelsCollectionRef, level);
    }
  };

  const saveLevels = async (levels: Levels) => {
    const db = useFirestore();
    const batch = writeBatch(db);

    for (var level of levels) {
      const levelRef = doc(db, "levels", level.id!);
      batch.set(levelRef, level);
    }

    await batch.commit();
  };

  return {
    allLevels,
    allUserLevels,
    productCategoryLevels,
    initializeLevels,
    fetchLevel,
    saveLevel,
    saveLevels,
  };
});
